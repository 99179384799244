import React from "react";
import { ReactDOM } from "react";

const Footer = () =>{
    return (
        <footer>
            <p>DevChallenges.io - challenge 1 - image uplad. DawiTEU</p>
        </footer>
    )
}


export default Footer;